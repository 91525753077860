/** 
 * Site alert
*/

const siteAlert = $('.js-site-alert');
const siteAlertButtonClose = $('.js-site-alert-close');

if (siteAlertButtonClose.length) {
    siteAlertButtonClose.each(function () {
        $(this).on('click', function () {
            $(this).attr('aria-expanded', 'false');
            $(this).closest('.js-site-alert').hide();
        });
    });
}