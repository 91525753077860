/**
 * Glossary
 */

if ($('html').attr('lang') == 'en-US') {

    var placeholder ='Begin typing an insurance term...'
} else if ($('html').attr('lang') == 'es-US') {
    var placeholder = 'Ingresa una palabra y da clic en buscar'
}


if ($(window).width() >= 1000 && $('.c-glossary__letter').length) {

    $(window).on('scroll', function () {

        $('.c-glossary__letter').each(function () {
            if ($(this)[0].getBoundingClientRect().top <= 100) {
                $(this).addClass('is-pinned')
            } else {
                $(this).removeClass('is-pinned')
            }
        });
    });
}

if ($('#searchGlossary').length) {
    console.log('glosario');


    let src = []
    $('.c-glossary__title').each(function () {
        let term = $(this).text();
        src.push(term);
    });
    accessibleAutocomplete({

        element: document.querySelector('#searchGlossary'),
        id: 'searchGlossaryLabel',
        source: src,
        placeholder: placeholder,
        confirmOnBlur: false,
        onConfirm: function (val) {
            let target = $('#' + $.escapeSelector(encodeURIComponent(val)));
            //console.log($.escapeSelector(encodeURIComponent(val));
            $('html, body').animate({
                scrollTop: target.offset().top - siteHeader.outerHeight() - 2
            }, function () {
                $(target).css('outline-offset', '-2px').attr('tabindex', -1).focus();
            });
            window.history.pushState({}, '', window.location + '#' + encodeURIComponent(val));
        }
    })
}
