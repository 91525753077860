  /**
 * blog toggle
 */

let blogCategoriesToggle = $('.c-blog-categories__toggle');
let blogCategoriesDialog = $('.c-blog-categories__list');

const closeBlogCategories = () => {
    blogCategoriesToggle.removeClass('is-open');
    blogCategoriesDialog.slideUp();
    blogCategoriesToggle.attr('aria-expanded', 'false');
}

const openBlogCategories = () => {
    blogCategoriesToggle.addClass('is-open');
    blogCategoriesDialog.slideDown();
    blogCategoriesToggle.attr('aria-expanded', 'true');
}

if (blogCategoriesToggle.length) {
    blogCategoriesToggle.on('click', () => {
        if (blogCategoriesToggle.hasClass('is-open')) {
            closeBlogCategories();
        } else {
            openBlogCategories();
        }
    });

    $(document).on('keydown', (e) => {
        if (e.key == 'Escape') { closeBlogCategories(); }
    });

    $(document).on('click', (e) => {
        if (!$(e.target).closest('.c-news-filter').length && blogCategoriesDialog.is(':visible')) {
            closeBlogCategories();
        }
    });
}