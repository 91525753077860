/** 
 * Site Header
*/

const mobileSiteMenuOpenButton = $('.js-mobile-site-menu-open');
const mobileSiteMenuCloseButton = $('.js-mobile-site-menu-close');
const mobileNavDialog = $('.js-mobile-nav-dialog');
const mobileNavLinkToggle = $('.js-mobile-nav-link-toggle');
const mobileNavOverlay = $('.c-mobile-nav-dialog__overlay');
const desktopNavLinkToggle = $('.js-desktop-nav-link-toggle');
const desktopNavListLvl2 = $('.js-desktop-nav-list-lvl-2');
const desktopNavLinkLvl2Toggle = $('.js-desktop-nav-link-lvl-2-toggle');
const desktopNavListLvl3 = $('.js-desktop-nav-list-lvl-3');
const desktopNavItem = $('.js-desktop-nav-item ');

let openMobileNavDialog = () => {
    mobileSiteMenuOpenButton.attr('aria-expanded', 'true');
    mobileSiteMenuCloseButton.attr('aria-expanded', 'true');
    mobileNavDialog.addClass('is-open');
    trapFocus(mobileNavDialog);
}

let closeMobileNavDialog = () => {
    mobileSiteMenuOpenButton.attr('aria-expanded', 'false');
    mobileSiteMenuCloseButton.attr('aria-expanded', 'false');
    mobileNavDialog.removeClass('is-open');
    unTrapFocus();
}

let toggleMobileSubMenuNav = (e) => {
    if ($(e.currentTarget).hasClass('is-open')) {
        $(e.currentTarget).attr('aria-expanded', 'false').removeClass('is-open').next().slideUp();
    } else {
        $(e.currentTarget).attr('aria-expanded', 'true').addClass('is-open').next().slideDown();
    }
}

let toggleDesktopSubMenuNav = (e) => {
    if ($(e.currentTarget).hasClass('is-open')) {
        $(e.currentTarget).attr('aria-expanded', 'false').removeClass('is-open').next().slideUp();
    } else {
        desktopNavListLvl2.filter(':visible').slideUp();
        desktopNavLinkToggle.attr('aria-expanded', 'false').removeClass('is-open');
        desktopNavLinkLvl2Toggle.attr('aria-expanded', 'false').removeClass('is-open');
        $(e.currentTarget).attr('aria-expanded', 'true').addClass('is-open').next().slideDown();
    }
}

let toggleDesktopSubMenuLvl2Nav = (e) => {
    if ($(e.currentTarget).hasClass('is-open')) {
        $(e.currentTarget).attr('aria-expanded', 'false').removeClass('is-open');
    } else {
        desktopNavLinkLvl2Toggle.attr('aria-expanded', 'false').removeClass('is-open');
        $(e.currentTarget).attr('aria-expanded', 'true').addClass('is-open');
    }
}

let closeAlldesktopNavListLvl2 = () => {
    desktopNavListLvl2.filter(':visible').slideUp();
    desktopNavListLvl2.filter(':visible').prev().attr('aria-expanded', 'false').removeClass('is-open');
    desktopNavListLvl2.filter(':visible').find('.is-open').attr('aria-expanded', 'false').removeClass('is-open');
} 


mobileSiteMenuOpenButton.on('click', openMobileNavDialog);
mobileSiteMenuCloseButton.on('click', closeMobileNavDialog);
mobileNavOverlay.on('click', closeMobileNavDialog);
mobileNavLinkToggle.on('click', toggleMobileSubMenuNav);
desktopNavLinkToggle.on('click', toggleDesktopSubMenuNav);
desktopNavLinkLvl2Toggle.on('click', toggleDesktopSubMenuLvl2Nav);

mobileNavDialog.on('keydown', (e) => {
    if (e.key == 'Escape') { closeMobileNavDialog(); }
});

$(document).on('keydown', function (e) {
    if (e.key == 'Escape') { 
        closeAlldesktopNavListLvl2();
        let target = $(e.target).closest('.js-desktop-nav-list-lvl-2');
        if (target.length) {
            target.prev().focus();
        }
    }
});
$(document).on('click', (e) => {
    if (!$(e.target).closest('.js-desktop-nav-item').length) {
        closeAlldesktopNavListLvl2();
    }
});