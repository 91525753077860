/**
 * Stop transitions
 */

let resizeTimer;

$(function() {
    $(window).on('resize', function() {
        $('body').addClass('u-resize-animation-stopper');
        clearTimeout(resizeTimer);
        resizeTimer = setTimeout(function() {
            $('body').removeClass('u-resize-animation-stopper');
        }, 400);
    });

});

$(window).on('load', function () {
    $('body').removeClass('is-loading')
});