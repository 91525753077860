/** 
 * Location Peak
*/

const locationPeakToggle = $('.js-location-peak-toggle');
const locationPeakClose = $('.js-location-peak-dialog-close');
const locationPeakDialog = $('.js-location-peak-dialog');

const closeLocationPeakDialog = () => {
    locationPeakToggle.removeClass('is-open');
    locationPeakDialog.slideUp();
    locationPeakToggle.attr('aria-expanded', 'false');
    unTrapFocus();
}

const openLocationPeakDialog = () => {
    locationPeakToggle.addClass('is-open');
    locationPeakDialog.slideDown();
    locationPeakToggle.attr('aria-expanded', 'true');
    trapFocus(locationPeakDialog);
}

if (locationPeakToggle.length) {
    locationPeakToggle.on('click', () => {
        if (locationPeakToggle.hasClass('is-open')) {
            closeLocationPeakDialog();
        } else {
            openLocationPeakDialog();
        }
    });

    locationPeakClose.on('click', () => {
        closeLocationPeakDialog();
    });
}

locationPeakDialog.on('keydown', (e) => {
    if (e.key == 'Escape') { closeLocationPeakDialog(); }
});

$(document).on('click', (e) => {
    if (!$(e.target).closest('.c-location-peak').length && locationPeakDialog.is(':visible')) {
        closeLocationPeakDialog();
    }
});