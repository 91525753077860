let linksHash = $('a[href^="#"]');
const siteHeader = $('.c-site-header');
let urlPage = window.location.href;
let idLinkPage = $('#'+$.escapeSelector(urlPage.split('#')[1]));


if (linksHash.length) {
    $(document).on('click', 'a[href^="#"] ', function (e) {
        //e.preventDefault();
        let targetLink = $(this).attr('href');
        let scrollTopTarget;
        if ($(this).hasClass('js-site-footer-go-to-top')) {
            e.preventDefault();
            scrollTopTarget = 0;
        } else {
            scrollTopTarget = $(targetLink).offset().top - siteHeader.outerHeight(); 
        }
        $('html, body').animate({
            scrollTop: scrollTopTarget
        }, function () {
            if ($(targetLink).is('select, input, textarea, button, a')) {
                $(targetLink).focus();
            } else {
                $(targetLink).css('outline-offset', '-2px').attr('tabindex', -1).focus();
            }
        });
        //console.log(siteHeader.outerHeight());
        //console.log(scrollTopTarget);
    });
}

if (idLinkPage.length) {
    $(window).on('load', function () {
        $('html, body').animate({
            scrollTop: idLinkPage.offset().top - siteHeader.outerHeight()
        });
        //console.log(idLinkPage.offset().top - siteHeader.outerHeight())
    });
}