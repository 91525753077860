/**
 * News filter
 */

let newsFilterToggle = $('.js-news-filter-select-button');
let newsFilterDialog = $('.js-news-filter-options');

const closeFilterDialog = () => {
    newsFilterToggle.removeClass('is-open');
    newsFilterDialog.slideUp();
    newsFilterToggle.attr('aria-expanded', 'false');
}

const openFilterDialog = () => {
    newsFilterToggle.addClass('is-open');
    newsFilterDialog.slideDown();
    newsFilterToggle.attr('aria-expanded', 'true');
}

if (newsFilterToggle.length) {
    newsFilterToggle.on('click', () => {
        if (newsFilterToggle.hasClass('is-open')) {
            closeFilterDialog();
        } else {
            openFilterDialog();
        }
    });

    $(document).on('keydown', (e) => {
        if (e.key == 'Escape') { closeFilterDialog(); }
    });

    $(document).on('click', (e) => {
        if (!$(e.target).closest('.c-news-filter').length && newsFilterDialog.is(':visible')) {
            closeFilterDialog();
        }
    });
}