/**
 * Tables
 */


if ($('.js-simple-table').length) {


    new ResizeSensor($('.js-simple-table'), function () {
        $('.js-simple-table').each(function () {
            let tableWidth = $(this).width();
            let tableContentWidth = $(this).find('.c-simple-table__table').width();
            let tableSwipeText = $(this).find('.js-simple-table-indicator');


            if (tableContentWidth > tableWidth) {
                $(this).addClass('has-overflow-x');
                tableSwipeText.addClass('is-active');
            } else {
                $(this).removeClass('has-overflow-x');
                tableSwipeText.removeClass('is-active');
            }
        });
    });
}